




















import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'Menu',
  components: { BaseIcon },
})
export default class Menu extends Vue {
  @Prop()
  small!: boolean

  menus = [
    {
      id: 1,
      title: 'Мои помещения',
      path: '/my-rooms',
      icon: 'my-premises',
    },
    { id: 2, title: 'Статистика', path: '/statistics', icon: 'statistics' },
    // {
    //   id: 3,
    //   title: 'Спец. цены',
    //   path: '/special-price',
    //   icon: 'special-price',
    // },
    // { id: 4, title: 'Календарь', path: '/calendar', icon: 'calendar' },
    // { id: 5, title: 'Клиенты', path: '/clients', icon: 'clients' },
    // { id: 6, title: 'Заявки', path: '/orders', icon: 'orders', isNotice: true },
    { id: 7, title: 'Профиль', path: '/profile', icon: 'profile' },
    // { id: 8, title: 'Премиум', path: '/premium', icon: 'premium' },
  ]
}
